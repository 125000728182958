import './styles/root/app.scss';
import { Navigate, Route, useLocation, useNavigate } from 'react-router-dom';
import { Routes } from 'react-router-dom';
import { useEffect, useLayoutEffect, useState } from 'react';
import history from './history';
import { fetchAppInit } from './lib/api/app-init';
import SignUpPage from './pages/sign-up/Signup';
import LoginPage from './pages/login/Login';
import SplashScreen from './components/splash-screen/SplashScreen';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from './store/store';
import { updateIntercom } from './store/reducers/userSlice';
import { AnimatePresence } from 'framer-motion';
import Message from './components/generic/Message';
import PageNotFound from './pages/pageNotFound/PageNotFound';
import EmailVerificationSuccess from './pages/redirects/EmailVerificationSuccess';
import StripeSubscriptionSuccess from './pages/redirects/StripeSubscriptionSuccess';
import StripeSubscriptionFailed from './pages/redirects/StripeSubscriptionFailed';
import ForgotPassword from './pages/forgotPassword/ForgotPassword';
import ResetPassword from './pages/forgotPassword/ResetPassword';
import i18n from './internationalization/i18n';
import AdUpPayOnboardingSuccess from './pages/redirects/AdUpPayOnboardingSuccess';
import OnboardingCompleteBanner from './ui/banners/OnboardingCompleteBanner';
import appThemeEngine from './lib/helper-functions/appThemeEngine';
import moment from 'moment';
import { tokenRevokeAtLogOutUser } from './lib/api/logout-user';
import { setMessage } from './store/reducers/appSlice';
import { useTranslation } from 'react-i18next';
import PluginAuthPage from './pages/plugin/PluginAuthPage';
import WhiteLabel from './pages/whiteLabel/WhiteLabel';
import {
  setIsOnboardingCompleteBannerOpen,
  setIsWhiteLabel,
} from './store/reducers/uiSlice';
import Unsubscribe from './pages/redirects/Unsubscribe';
import SettingsPage from './pages/settings/SettingsPage';
import React from 'react';
import PortalLogin from './pages/login-via-portal/PortalLogin';
import CheckoutTrackingPage from './pages/checkoutTracking/CheckoutTrackingPage';
import Dashboard from './pages/dashboard/Dashboard';
import HeaderMain from './components/header/HeaderMain';
import { runCacheBuster } from './lib/cache-buster/cache-bust-handler';
import {
  APP_ENV,
  CACHE_BUSTER_EXECUTION_INTERVAL,
  GOOGLE_OAUTH_CLIENT_ID,
} from './lib/constants/central-api';
import isNotEmptyString from './lib/helper-functions/isNotEmptyString';
import { allRoutes, HOME_ROUTE } from './lib/constants/route';
import ProductsPage from './pages/products/ProductsPage';
import OrdersPage from './pages/orders/OrdersPage';
import IntegrationsPage from './pages/integrations/IntegrationsPage';
import RecommendationsSubscriptionStatus from './pages/redirects/RecommendationsSubscriptionStatus';
import AIOnboarding from './pages/ai-onboarding/AIOnboarding';
import { GoogleOAuthProvider } from '@react-oauth/google';
import localStorageHardReset from './lib/helper-functions/localStorageHardReset';

function App() {
  const { t } = useTranslation();
  const location = useLocation();
  const currentRoute = location.pathname;

  const [isSidebarDrawerOpen, setIsSidebarDrawerOpen] =
    useState<boolean>(false);

  const toggleSidebarDrawer = () => {
    setIsSidebarDrawerOpen(!isSidebarDrawerOpen);
  };

  /* get onboarding data */
  const staticURL = useSelector((state: RootState) => state.app.staticURL);
  const onboardingObject = useSelector(
    (state: RootState) => state.user.onboarding.onboarding_object
  );
  const userProfile = useSelector((state: RootState) => state.user.profile);
  const merchantTheme = useSelector(
    (state: RootState) => state.user.merchant.config
  );
  const intercomData = useSelector((state: RootState) => state.user.intercom);
  const accessToken = useSelector(
    (state: RootState) => state.auth.authorization.accessToken
  );
  const subscriptionLock = useSelector(
    (state: RootState) => state.auth.authorization.subscriptionLock
  );
  const message = useSelector((state: RootState) => state.app.message);
  const isInitSuccess = useSelector(
    (state: RootState) => state.app.isInitSuccess
  );
  const appLanguage = useSelector((state: RootState) => state.app.language);
  const isAppThemeDark = useSelector((state: RootState) => state.UI.theme.dark);
  const appThemeType = useSelector((state: RootState) => state.UI.theme.type);
  const isWhiteLabel = useSelector((state: RootState) => state.UI.isWhiteLabel);
  const isOnboardingCompleteBannerOpen = useSelector(
    (state: RootState) => state.UI.isOnboardingCompleteBannerOpen
  );
  const lastVersionCheckTimestamp = useSelector(
    (state: RootState) => state.app.lastVersionCheckTimestamp ?? 0
  );
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const is_ai_onboarding_inprogress = useSelector(
    (state: RootState) => state.user?.ai_onboarding?.is_ai_onboarding_inprogress
  );

  useEffect(() => {
    if (is_ai_onboarding_inprogress) {
      navigate('/onboarding');
    }
  }, [is_ai_onboarding_inprogress]);

  useEffect(() => {
    const timer = setTimeout(() => {
      dispatch(setIsOnboardingCompleteBannerOpen(false));
      clearTimeout(timer);
    }, 4000);
  }, [isOnboardingCompleteBannerOpen, dispatch]);

  useLayoutEffect(() => {
    const unlisten = history.listen(({ location, action }) => {
      let unblock = history.block((tx) => {
        // Navigation was blocked! Let's show a confirmation dialog
        // so the user can decide if they actually want to navigate
        // away and discard changes they've made in the current page.
        let url = tx.location.pathname;
        if (window.confirm(`Are you sure you want to go to ${url}?`)) {
          // Unblock the navigation.
          unblock();

          // Retry the transition.
          tx.retry();
        }
      });
    });

    return unlisten;
  }, []);

  //changing app language
  useEffect(() => {
    i18n.changeLanguage(appLanguage);
  }, [appLanguage]);

  useEffect(() => {
    if (currentRoute !== '/authorize') {
      dispatch(setIsWhiteLabel(false));
    }
    if (currentRoute !== '/authorize' && currentRoute !== '/login-via-portal') {
      fetchAppInit();
    }
  }, []);

  //* ******************** Run Cache Buster (FE version check) at given interval --- START ******************** *//
  //* Extracting cacheBustToken from URL if there is any
  const queryParameters = new URLSearchParams(window.location.search);
  const cacheBustToken = queryParameters.get(
    'cacheBustToken'
  ) as unknown as string;

  useEffect(() => {
    const interval = setInterval(() => {
      const currentTime: number = new Date().getTime();
      const hoursDifference: number =
        (currentTime - lastVersionCheckTimestamp) / (1000 * 60 * 60);

      //? Check if the last version check was more than 12 hours ago 👇🏼
      if (isInitSuccess && hoursDifference >= 12) {
        console.log(
          '🔄 CacheBuster : (FE version check) 12 hour interval reached ⏰'
        );
        runCacheBuster();
      }
    }, CACHE_BUSTER_EXECUTION_INTERVAL);

    return () => clearInterval(interval);
  }, [isInitSuccess]);
  //* ******************** Run Cache Buster (FE version check) at given interval --- END ******************** *//

  /* --------------Token expiry check Start---------------- */
  useEffect(() => {
    if (!isNotEmptyString(cacheBustToken)) {
      console.log(
        '⏰ 🚧 Current access token will be expired on :',
        accessToken?.expiresOn
      );
      if (
        currentRoute !== '/authorize' &&
        currentRoute !== '/login-via-portal'
      ) {
        if (accessToken?.expiresOn && accessToken?.expiresOn !== null) {
          if (moment(accessToken?.expiresOn) < moment()) {
            console.log('❌ Access token expired. Logging Out user');
            logoutHandler();
            dispatch(
              setMessage({
                message: t('SessionExpired'),
                messageType: 'error',
              })
            );
          } else {
            console.log('✅ Access token still active');
          }
        } else {
          logoutHandler();
        }
      }
    }
  }, [accessToken?.expiresOn]);
  /* --------------Token expiry check End---------------- */

  /* --------------App Theme Settings Start---------------- */
  useEffect(() => {
    appThemeEngine(isAppThemeDark, merchantTheme, appThemeType);
  }, [isAppThemeDark, merchantTheme]);
  /* --------------App Theme Settings End------------------ */

  /* --------------Setting Favicon Start---------------- */
  useEffect(() => {
    const link = document.querySelector("link[rel*='icon']") as HTMLLinkElement;

    if (merchantTheme?.merchant_favicon) {
      link.href = `${staticURL}/medium/${merchantTheme.merchant_favicon}`;
      console.log('has merchant favicon');
    } else {
      link.href = '/favicon.ico'; // Assuming the favicon is located in the public folder
      console.log('no merchant favicon');
    }
  }, [staticURL, merchantTheme]);
  /* --------------Setting Favicon End------------------ */

  /* -------------run intercom when loggedin user data loads code start--------------- */
  useEffect(() => {
    if (
      // @ts-ignore:next-line
      window.Intercom &&
      userProfile.first_name &&
      accessToken?.sellerToken &&
      APP_ENV === 'LIVE' //*  Intercom only enabled in LIVE
    ) {
      if (!intercomData.isFirstBootComplete) {
        console.log('intercom first boot running...');
        // @ts-ignore:next-line
        window.Intercom('boot', {
          app_id: intercomData.app_id,
          name: `${userProfile?.first_name} ${userProfile?.last_name}`,
          email: `${userProfile?.email}`,
          phone: `+${userProfile?.country_code}${userProfile?.phone}`,
          api_base: 'https://api-iam.intercom.io',
        });

        dispatch(
          updateIntercom({
            isFirstBootComplete: true,
          })
        );
      }
      // @ts-ignore:next-line
      window.intercomSettings = {
        email: `${userProfile.email}`,
        app_id: intercomData.app_id,
        cms: intercomData.cms,
        cmsdatafilled: intercomData.cmsdatafilled,
        psp: intercomData.psp,
        pspdatafilled: intercomData.pspdatafilled,
      };

      console.log('intercom updating...');
      // @ts-ignore:next-line
      window.Intercom('update', {
        app_id: intercomData.app_id,
        name: `${userProfile.first_name} ${userProfile.last_name}`,
        email: `${userProfile.email}`,
        api_base: 'https://api-iam.intercom.io',
      });
    }
    // @ts-ignore:next-line
    /* console.log(window.intercomSettings); */
  }, [APP_ENV, userProfile, accessToken?.sellerToken, intercomData, dispatch]);
  /* -------------run intercom when loggedin user data loads code end--------------- */

  const logoutHandler = () => {
    tokenRevokeAtLogOutUser();
    if (APP_ENV === 'LIVE') {
      // @ts-ignore:next-line
      window.Intercom && window.Intercom('shutdown'); //shutdown intercom
    }

    localStorageHardReset();

    if (isWhiteLabel) {
      navigate('/authorize');
    } else {
      navigate(
        window?.location?.pathname === '/register' ? '/register' : '/login'
      );
      fetchAppInit();
    }
  };

  const renderHeader = () => {
    if (window?.location?.pathname === '/onboarding') {
      return null;
    }
    if (accessToken?.sellerToken || isNotEmptyString(cacheBustToken)) {
      return (
        <HeaderMain
          isSidebarDrawerOpen={isSidebarDrawerOpen}
          toggleSidebarDrawer={toggleSidebarDrawer}
        />
      );
    }

    return null;
  };

  return (
    <div data-is-white-label={isWhiteLabel?.toString()} className='App'>
      <AnimatePresence>
        {message.messageType && (
          <Message msgType={message.messageType} msg={message.message} />
        )}
        {isOnboardingCompleteBannerOpen && <OnboardingCompleteBanner />}
      </AnimatePresence>

      {renderHeader()}
      {/* //* App Routes --- START */}
      <React.Fragment>
        {!isInitSuccess &&
        window?.location?.pathname !== '/authorize' &&
        window?.location?.pathname !== '/login-via-portal' ? (
          <AnimatePresence>
            <SplashScreen />
          </AnimatePresence>
        ) : (
          <Routes>
            <Route
              path='/'
              element={
                isWhiteLabel ? (
                  <Navigate to={'/authorize'} />
                ) : (
                  <Navigate to={'/login'} />
                )
              }
            />
            <Route
              path='/register'
              element={
                !accessToken?.sellerToken &&
                !isNotEmptyString(cacheBustToken) &&
                !isWhiteLabel ? (
                  <GoogleOAuthProvider clientId={GOOGLE_OAUTH_CLIENT_ID}>
                    <SignUpPage />
                  </GoogleOAuthProvider>
                ) : (
                  <Navigate to={'/onboarding'} />
                )
              }
            />
            <Route
              path='/login'
              element={
                !accessToken?.sellerToken &&
                !isNotEmptyString(cacheBustToken) &&
                !isWhiteLabel ? (
                  <GoogleOAuthProvider clientId={GOOGLE_OAUTH_CLIENT_ID}>
                    <LoginPage />
                  </GoogleOAuthProvider>
                ) : !isWhiteLabel ? (
                  <Navigate to={HOME_ROUTE} />
                ) : (
                  <Navigate to={'/authorize'} />
                )
              }
            />
            <Route
              path='/forgot-password'
              element={
                !accessToken?.sellerToken &&
                !isNotEmptyString(cacheBustToken) &&
                !isWhiteLabel ? (
                  <ForgotPassword />
                ) : !isWhiteLabel ? (
                  <Navigate to={HOME_ROUTE} />
                ) : (
                  <Navigate to={'/authorize'} />
                )
              }
            />
            <Route
              path='/reset-password'
              element={
                !accessToken?.sellerToken &&
                !isNotEmptyString(cacheBustToken) &&
                !isWhiteLabel ? (
                  <ResetPassword />
                ) : !isWhiteLabel ? (
                  <Navigate to={HOME_ROUTE} />
                ) : (
                  <Navigate to={'/authorize'} />
                )
              }
            />
            <Route
              path='/onboarding'
              element={
                accessToken?.sellerToken ||
                accessToken?.currentWhiteLabelSellerToken ||
                isNotEmptyString(cacheBustToken) ? (
                  // <Onboarding />
                  <AIOnboarding />
                ) : !isWhiteLabel ? (
                  <Navigate to={'/login'} />
                ) : (
                  <Navigate to={'/authorize'} />
                )
              }
            />
            {allRoutes['/products'] && (
              <Route
                path='/products/*'
                element={
                  subscriptionLock && subscriptionLock === 'true' ? (
                    <Navigate to={'/settings/subscription'} />
                  ) : accessToken?.sellerToken ||
                    isNotEmptyString(cacheBustToken) ||
                    isWhiteLabel ? (
                    <ProductsPage />
                  ) : (
                    <Navigate to={'/login'} />
                  )
                }
              />
            )}
            {allRoutes['/orders'] && (
              <Route
                path='/orders/*'
                element={
                  subscriptionLock && subscriptionLock === 'true' ? (
                    <Navigate to={'/settings/subscription'} />
                  ) : accessToken?.sellerToken ||
                    isNotEmptyString(cacheBustToken) ||
                    isWhiteLabel ? (
                    <OrdersPage />
                  ) : (
                    <Navigate to={'/login'} />
                  )
                }
              />
            )}

            {allRoutes['/dashboard'] && (
              <Route
                path='/dashboard/'
                element={
                  accessToken?.sellerToken ||
                  isNotEmptyString(cacheBustToken) ||
                  isWhiteLabel ? (
                    <Dashboard />
                  ) : (
                    <Navigate to={'/login'} />
                  )
                }
              />
            )}
            {allRoutes['/settings'] && (
              <Route
                path='/settings/*'
                element={
                  accessToken?.sellerToken ||
                  isNotEmptyString(cacheBustToken) ||
                  isWhiteLabel ? (
                    <SettingsPage />
                  ) : (
                    <Navigate to={'/login'} />
                  )
                }
              />
            )}
            {allRoutes['/integrations'] && (
              <Route
                path='/integrations/*'
                element={
                  accessToken?.sellerToken ||
                  isNotEmptyString(cacheBustToken) ||
                  isWhiteLabel ? (
                    <IntegrationsPage />
                  ) : (
                    <Navigate to={'/login'} />
                  )
                }
              />
            )}
            {allRoutes['/tracking'] && (
              <Route
                path='/tracking/*'
                element={
                  accessToken?.sellerToken ||
                  isNotEmptyString(cacheBustToken) ||
                  isWhiteLabel ? (
                    <CheckoutTrackingPage />
                  ) : (
                    <Navigate to={'/login'} />
                  )
                }
              />
            )}
            <Route
              path='/plugin-connect'
              element={
                subscriptionLock && subscriptionLock === 'true' ? (
                  <Navigate to={'/settings/subscription'} />
                ) : accessToken?.sellerToken ||
                  isNotEmptyString(cacheBustToken) ? (
                  <PluginAuthPage />
                ) : !isWhiteLabel ? (
                  <Navigate to={'/login'} />
                ) : (
                  <Navigate to={'/authorize'} />
                )
              }
            />
            <Route
              path='/email-verification-success'
              element={<EmailVerificationSuccess />}
            />
            <Route path='/unsubscribe' element={<Unsubscribe />} />
            <Route
              path='/stripe-subscription-success'
              element={
                accessToken?.sellerToken || isNotEmptyString(cacheBustToken) ? (
                  <StripeSubscriptionSuccess />
                ) : !isWhiteLabel ? (
                  <Navigate to={'/login'} />
                ) : (
                  <Navigate to={'/authorize'} />
                )
              }
            />
            <Route
              path='/stripe-subscription-failed'
              element={
                accessToken?.sellerToken || isNotEmptyString(cacheBustToken) ? (
                  <StripeSubscriptionFailed />
                ) : !isWhiteLabel ? (
                  <Navigate to={'/login'} />
                ) : (
                  <Navigate to={'/authorize'} />
                )
              }
            />
            <Route
              path='/recommendations-subscription-status'
              element={
                accessToken?.sellerToken || isNotEmptyString(cacheBustToken) ? (
                  <RecommendationsSubscriptionStatus />
                ) : !isWhiteLabel ? (
                  <Navigate to={'/login'} />
                ) : (
                  <Navigate to={'/authorize'} />
                )
              }
            />
            <Route
              path='/aduppay/onboarding'
              element={
                accessToken?.sellerToken || isNotEmptyString(cacheBustToken) ? (
                  <AdUpPayOnboardingSuccess />
                ) : !isWhiteLabel ? (
                  <Navigate to={'/login'} />
                ) : (
                  <Navigate to={'/authorize'} />
                )
              }
            />
            <Route path='/authorize' element={<WhiteLabel />} />
            <Route path='/login-via-portal' element={<PortalLogin />} />
            <Route path='*' element={<PageNotFound />} />
          </Routes>
        )}
      </React.Fragment>
    </div>
  );
}

export default App;
